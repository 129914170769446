<template>
<main class="container">
  <div class="header h3 mt-2">Inicio</div>

  <div class="opaque">

  </div>
</main>
</template>

<script>

export default {  
  
}
</script>
